<template>
<div>
    <Header></Header>
    <!--顶部banner开始-->
    <div class="aboutus-banner left relative" style="margin-bottom:20px;"> <img src="~images/banner/qiyezhaopin.jpg" width="100%" /> </div>

    <!--内容开始-->
    <div class="width-bai left clearfix">
            <div class="recruit_content center clearfix">
                    <div class="width-bai left clearfix">
                    <div class="recruit-content center">

                    <!--面包屑开始-->
                        <Breadcrumb>
                        <a href="/#/index" title="首页"> <BreadcrumbItem>首页</BreadcrumbItem> </a>
                        <BreadcrumbItem>企业招聘</BreadcrumbItem>
                        </Breadcrumb>
                    <div style="height:20px;"></div>

                    <!--循环内容开始-->
                    <div class="recruit-li" v-for="item in list" :key="item.id">
                        <h1>{{item.title}}</h1>
                        <h2>{{item.wages}} / {{item.experience}} / {{item.education}} / {{item.position}}</h2>
                        <h3>职位描述：</h3>
                        <div class="recruit-text" v-html="item.content"> {{item.content}} </div>
                        
                        <div class="recruit-text">
                            <p style="color:rgb(242,151,0)">工作地点：{{item.place}} 
                            <a :href="'/#/recruit_form?recruit='+item.id"> <button>申请该职位</button> </a>
                            </p>
                        </div>
                    </div>
                    </div>
                    </div>
            </div>
        </div>

        <div class="left clearfix width-bai"> <Footer></Footer> </div>
        <contact-call></contact-call>
</div>
</template>

<script>
import ContactCall from '../index/ContactCall.vue'
import Header from '../layout/Header.vue'
import Footer from '../layout/Footer.vue'

export default {
   name:"",
   data () {
            return {
                
                list:''
            }
        },
   components:{
       ContactCall,
       Header,
       Footer
   },
methods:{
     alertRecruit(id){
            this.value4=true;
            this.id=id;
     }
},

mounted() {
  this.$axios.post(window.configs.svcProt + "://" + window.configs.svcIpPort + '/get_recruit.html?token='+this.$store.state.token)
  .then(res=>{
    this.list=res.data.result;
  });
},
}
</script>

<style scoped>
    .recruit-form{width:80%;margin:20px;}
    .recruit-box h1{font-size:15px;border-left:5px solid rgb(242,151,0);padding-left:10px;margin-bottom:40px;}
    .recruit-box{width:100%;min-height:800px;}
    .recruit-text button{width:120px;cursor:pointer;line-height:35px;background:rgb(242,151,0);color:#fff;border-radius:5px;border:none;float:right;}
    .recruit-text{padding-left:40px;line-height:35px;}
    .recruit-text p{line-height:35px;font-size:15px;}
    .recruit-li h3{font-size:18px;line-height:60px;padding-left:20px;}
    .recruit-li h1{font-size:22px;color:#666;line-height:40px;}
    .recruit-li h2{font-size:15px;color:rgb(242,151,0);line-height:40px;}
    .recruit-li{width:1440px;min-height:400px;box-shadow: 0px 2px 5px #cccccc;cursor:pointer;padding:20px;margin-top:20px;margin-bottom:40px;}
    .recruit-li:hover{box-shadow: 0px 2px 5px rgb(242,151,0);}
    .recruit-content{min-height:800px;width:1440px;}
</style>
